<div class="section">
  <div #main class="main">
    <div class="chtl-header">
      <a href="/#conditions"><img class="app-logo" src="assets/images/applog.png" alt="logo" /></a>
      <app-navigation></app-navigation>
    </div>
    <div class="app-text-main">
      <p cds-text class="strong">الفـائـزيـــن</p>
      <p cds-text class="light">
        بمنح التفوق لفائدة أبناء الفلاحين شركاء
        <strong style="color: #fed500">كوسومار</strong>
      </p>
      <p cds-text class="light">
        الحاصلين على شهادة الباكالوريا
        <strong class="chtl-strong-number"> 2021-2022 </strong> بإمتياز
      </p>
    </div>
    <div class="doted">
      <a (mouseover)="pauseSlider(true, sliders.indexOf(slider))"
        (mouseleave)="pauseSlider(false, sliders.indexOf(slider))" (click)="setSliderIndex(sliders.indexOf(slider))"
        [ngClass]="{ 'dote-active': slider === sliders[sliderCurrentIndex] }" *ngFor="let slider of sliders">
        {{ slider }}
      </a>
    </div>
    <div class="slider">
      <div class="slide" [ngClass]="{ 'img-in': 3 === sliderCurrentIndex }">
        <img src="assets/images/sut_1.png" />
      </div>
      <div class="slide" [ngClass]="{ 'img-in': 2 === sliderCurrentIndex }">
        <img src="assets/images/sun_1.png" />
        <img src="assets/images/sun_2.png" />
        <img src="assets/images/sun_3.png" />
        <img src="assets/images/sun_4.png" />
      </div>
      <div class="slide" [ngClass]="{ 'img-in': 1 === sliderCurrentIndex }">
        <img src="assets/images/sur_1.png" />
        <img src="assets/images/sur_2.png" />
      </div>
      <div class="slide" [ngClass]="{ 'img-in': 0 === sliderCurrentIndex }">
        <img src="assets/images/d_1.png" />
        <img src="assets/images/d_2.png" />
        <img src="assets/images/d_3.png" />
        <img src="assets/images/d_4.png" />
        <img src="assets/images/d_5.png" />
      </div>
      <div class="slide" [ngClass]="{ 'img-in': 4 === sliderCurrentIndex }">
        <img src="assets/images/z_1.png" />
        <img src="assets/images/z_2.png" />
      </div>
    </div>
    <img src="assets/images/main-footer.png" alt="" style="width: 100vw;" />
  </div>
</div>
<div class="chtl-footer">
  <app-footer></app-footer>
</div>