<app-socials></app-socials>

<input type="checkbox" class="menyAvPaa" id="menyAvPaa">
<label id="burger" for="menyAvPaa">
  <div></div>
  <div></div>
  <div></div>
</label>
<div class="chtl-navigation" #mm>
  <div class="chtl-nav" *ngFor="let nav of navigations">
    <a class='chtl-link' onclick="stopConfettiInner()" [routerLink]="nav.url" [routerLinkActive]="'chtl-active'"
      [href]="nav.url">
      {{nav.name}}
    </a>
  </div>
</div>
<div class="hb-modal ng-star-inserted" *ngIf="is_open">
  <div class="application-modal">
    <div class="close-modal" (click)="closeModal()">X</div>
  </div>
  <div class="modal-backdrop"></div>
</div>