import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Sanitizer, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ClarityIcons, barsIcon } from '@cds/core/icon';
import { environment } from 'src/environments/environment';

ClarityIcons.addIcons(barsIcon);

@Component({
  selector: 'app-paq',
  templateUrl: './paq.component.html',
  styleUrls: ['./paq.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PaqComponent implements OnInit {
  faqs: any = [
    {
      title: "من يمكنه المشاركة في المسابقة؟",
      "content": "مسابقة \"المنح التشجيعية لفائدة أبناء الفلاحين شركاء كوسومار\" هي مسابقة منظمة كل سنة من طرف مجموعة كوسومار و مفتوحة أمام أبناء الفلاحين المتعاقدين مع شركة كوسومار و الحاصلين على شهادة الباكالوريا بامتياز.\nهذه النسخة تهم فقط أبناء الفلاحين المتعاقدين مع شركة كوسومار و الحاصلين على شهادة الباكالوريا بامتياز برسم الموسم الدراسي 2024-2023 .\n"
    },
    {
      title: "كيفية المشاركة في المسابقة؟",
      "content": "المشاركة في هذه المسابقة مجانية وحصرية على هذا الموقع. على المشاركين اتباع المراحل الثلاثة المشار إليها على الموقع لإدخال البيانات الخاصة بهم وارسال الوثائق المطلوبة."
    },
    {
      title: "ماهي مواعيد بداية ونهاية التسجيل الخاصة بهذه النسخة من المسابقة ؟",
      "content": "التسجيل مفتوح حصريا على نفس الموقع من 01/07/2024 إلى غاية 31/08/2024\nسيتم الإعلان على الفائزين على نفس الموقع ابتداءا من : 15/10/2024\n"
    },
    {
      title: "ما هي شروط قبول ملف الترشيح؟",
      "content": "· أن يكون المرشح من أبناء الفلاحين منتجي النباتات السكرية\n· أن يكون اسم الفلاح المنتج مسجل في لائحة المنتجين للموسم 2024 - 2023\n· أن تكون شهادة الباكالوريا محررة من مؤسسات التعليم العمومي\n· أن لا يتجاوز عمر المرشح 19 سنة عند حصوله على شهادة الباكالوريا\n· أن يكون المرشح حاصل على شهادة الباكالوريا بميزة حسن على الأقل.\nكل مترشح هو مسؤول عن المعلومات والوثائق المدلى بها. من أجل التأكد من صحة الملف يمكن للجنة تحكيم المسابقة طلب معلومات أو وثائق إضافية وذلك بمراسلتكم عبر بريدكم الالكتروني."
    },
    {
      title: "ما هو رقم بطاقة الفلاح أو الكود AG؟   ",
      "content": "رقم بطاقة الفلاح أو الكود AG هو الرقم الخاص بكل منتج للنباتات السكرية متعاقد مع شركة كوسومار والمشار اليه في بطاقة الفلاح."
    },
    {
      title: "هل يمكنني أن أرسل ملف الترشيح مباشرة إلى معمل السكر التابع لمنطقتي؟",
      "content": "في إطار سياسة الرقمنة التي تنهجها مجموعة كوسومار تم إنشاء هذا الموقع الالكتروني من أجل تبسيط عملية الترشيح و تسهيل تدبير هذه المسابقة. لهذا نشكركم على إيداع ملف الترشيح حصريا على الموقع الخاص بالمسابقة مع الادلاء بجميع المعلومات والوثائق المطلوبة دون استثناء.\nيمكن للمترشحين الاستناد بالمستشارين الفلاحيين لشركة كوسومار التابعين لمنطقتهم قصد ارشادهم ومساعدتهم على تقديم طلبهم عبر الموقع الالكتروني."
    },
    {
      title: "كيف يمكنني رفع الوثائق وما صيغة الملفات المرفقة المقبولة ؟",
      "content": "يجب تصوير الوثائق الأصلية بجودة عالية و المصادقة عليها من طرف السلطات المحلية ثم رفعها بصيغة .JPG أوPDF"
    }
  ]

  url = '/api/method/qst-answer';

  constructor(private sanitizer: DomSanitizer, private client: HttpClient) { }

  async ngOnInit() {
    await this.client
      .get(`${environment.apiUrl}${this.url}`, {
        headers: {
          Authorization: `token ${environment.apiKey}`,
        },
      })
      .toPromise()
      .then((res: any) => {
        this.faqs = res.data.map((qa: any) => {
          return {
            title: qa.qst,
            content: qa.content,
          };
        });
      })
      .catch((err) => { });

    this.faqs = this.faqs.map((faq: any) => {
      faq.content = this.sanitizer.bypassSecurityTrustHtml(
        faq.content.replace(/\d+/g, function (v: any) {
          return (
            `<span style="font-family: 'ITC Avant Garde Gothic Std Ligh', sans-serif;">` +
            v +
            '</span>'
          );
        })
      );
      return faq;
    });
  }
}
