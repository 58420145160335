import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ClrTimelineStepState } from '@clr/angular';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';

import {
  ClarityIcons,
  fileGroupIcon,
  fileIcon,
  checkCircleIcon,
  uploadIcon,
} from '@cds/core/icon';

ClarityIcons.addIcons(fileGroupIcon);
ClarityIcons.addIcons(fileIcon);
ClarityIcons.addIcons(uploadIcon);
ClarityIcons.addIcons(checkCircleIcon);

@Component({
  selector: 'app-bch-application',
  templateUrl: './bch-application.component.html',
  styleUrls: ['./bch-application.component.scss'],
})
export class BchApplicationComponent implements OnInit {
  options = [
    'علوم الزراعة',
    'العلوم الاقتصادية',
    'الآداب',
    'تقنيات الإدارة والمحاسبة',
    'علوم الحياة و الأرض',
    'العلوم الرياضية أ',
    'العلوم الرياضية ب',
    'العلوم الاجتماعية',
    'علوم الشريعة',
    'اللغة العربية',
    'الفنون التطبيقية',
    'العلوم الفيزيائية',
    'العلوم والتقنيات الكهربائية',
    'العلوم و التقنيات الميكانيكية',
  ];

  grades = ['حسن', 'حسن جدا'];
  graduation_year_options = ['2021-2022'];
  first_step_state: ClrTimelineStepState = ClrTimelineStepState.PROCESSING;
  first_third_state: ClrTimelineStepState = ClrTimelineStepState.NOT_STARTED;
  first_second_state: ClrTimelineStepState = ClrTimelineStepState.NOT_STARTED;
  code: string | undefined;
  graduation_year: string | undefined;
  showErrorMessage = false;
  errorMessage = '';
  url = '/api/method/';
  date = new Date();
  loadingVisible = false;
  step = 1;
  openModal = false;
  school_options = [];
  file?: File;
  file2?: File;
  file3?: File;

  cin_recto?: File;
  cin_verso?: File;
  photo_perso?: File;
  bilan_cmp?: File;
  contrat_ag?: File;







  student_info: any = {
    first_name: '',
    last_name: '',
    date_of_birth: '',
    number_of_siblings: '',
    email: '',
    tel: null,
    cin: '',
    file_name: null,
  };
  school_info: any = {
    hight_school: null,
    note: null,
    option: null,
    grade: null,
    grades_file_name: null,
    degree_file_name: null,
  };
  img0: any = null;
  img1: any = null;
  img2: any = null;
  img4: any = null;
  img5: any = null;
  img6: any = null;
  img7: any = null;
  img8: any = null;
  go = false;

  constructor(private client: HttpClient, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    if (localStorage.getItem('code')) {
      this.code = localStorage.getItem('code')!;
    }
    if (localStorage.getItem('student_info')) {
      this.student_info = JSON.parse(localStorage.getItem('student_info')!);
      this.student_info.file = null;
    }
  }

  fileDropped(e: any, cnt?: any) {

  }
  onFileSelected(e: any, cnt?: any) {
    console.log(e);

    if (cnt === 1) {
      this.file2 = e.length ? e[0] : e.target.files[0];
      this.school_info.degree_file_name = this.file2?.name;
      this.img1 = this.sanitizer.bypassSecurityTrustUrl(
        URL.createObjectURL(this.file2)
      );
    }
    if (cnt === 2) {
      this.file3 = e.length ? e[0] : e.target.files[0];
      this.school_info.grades_file_name = this.file3?.name;
      this.img2 = this.sanitizer.bypassSecurityTrustUrl(
        URL.createObjectURL(this.file3)
      );
    }
    if (cnt === 4) {
      this.cin_recto = e.length ? e[0] : e.target.files[0];
      this.school_info.cin_recto = this.cin_recto?.name;
      this.img4 = this.sanitizer.bypassSecurityTrustUrl(
        URL.createObjectURL(this.cin_recto)
      );
    }
    if (cnt === 5) {
      this.cin_verso = e.length ? e[0] : e.target.files[0];
      this.school_info.cin_verso = this.cin_verso?.name;
      this.img5 = this.sanitizer.bypassSecurityTrustUrl(
        URL.createObjectURL(this.cin_verso)
      );
    }
    if (cnt === 6) {
      this.photo_perso = e.length ? e[0] : e.target.files[0];
      this.school_info.photo_perso = this.photo_perso?.name;
      this.img6 = this.sanitizer.bypassSecurityTrustUrl(
        URL.createObjectURL(this.photo_perso)
      );
    }
    if (cnt === 7) {
      this.bilan_cmp = e.length ? e[0] : e.target.files[0];
      this.school_info.bilan_cmp = this.bilan_cmp?.name;
      this.img7 = this.sanitizer.bypassSecurityTrustUrl(
        URL.createObjectURL(this.bilan_cmp)
      );
    }
    if (cnt === 8) {
      this.contrat_ag = e.length ? e[0] : e.target.files[0];
      this.school_info.contrat_ag = this.contrat_ag?.name;
      this.img8 = this.sanitizer.bypassSecurityTrustUrl(
        URL.createObjectURL(this.contrat_ag)
      );
    }
    if (!cnt) {
      this.file = e.length ? e[0] : e.target.files[0];
      this.student_info.file = this.file?.name;
      this.img0 = this.sanitizer.bypassSecurityTrustUrl(
        URL.createObjectURL(this.file)
      );
    }
  }

  async sumbitForm(f: NgForm) {
    this.go = true;
    if (this.step === 1 && this.go) {
      if (!this.code) {
        this.showErrorMessage = true;
        this.errorMessage = 'المرجو إدخال رقم المنتج الخاص بالأب أو الأم';
        return;
      }

      const regex = /AG[0-9]+/g;
      const mt = this.code.match(regex);

      if (!mt) {
        this.showErrorMessage = true;
        this.errorMessage = 'المرجو إدخال رقم المنتج الخاص بالأب أو الأم';
        return;
      }

      this.loadingVisible = true;
      await this.client
        .post(
          `${environment.apiUrl}${this.url}check-ag`,
          {
            code_ag: this.code
          },
          {
            headers: {
              Authorization: `token ${environment.apiKey}`,
            },
          }
        )
        .toPromise()
        .then((res: any) => {
          if (res.data) {
            this.step = 2;
            this.go = false;
            this.showErrorMessage = false;
            this.first_step_state = ClrTimelineStepState.SUCCESS;
            this.first_second_state = ClrTimelineStepState.PROCESSING;
          } else {
            this.showErrorMessage = true;
            this.errorMessage = '  رقم المنتج الخاص بالأب أو الأم غير صحيح';
            this.first_step_state = ClrTimelineStepState.ERROR;
          }
        })
        .catch((err) => {
          this.showErrorMessage = true;
          this.errorMessage = '  رقم المنتج الخاص بالأب أو الأم غير صحيح';
        });
      this.loadingVisible = false;
    }

    if (this.step === 2 && this.go) {
      localStorage.setItem('code', this.code!);
      console.log(f);

      if (!f.valid) {
        this.showErrorMessage = true;
        this.errorMessage = 'المرجو ملئ جميع المعلومات الضرورية';
        return;
      }

      const regex = /0[567][0-9]{8}/g;
      const phone_regex = this.student_info.tel.match(regex);

      if (!phone_regex && this.student_info.tel) {
        this.showErrorMessage = true;
        this.errorMessage = 'المرجو التأكد من صحة رقم الهاتف';
        return;
      }

      this.showErrorMessage = false;

      let formData = new FormData();
      formData.append('file', this.cin_recto!, this.cin_recto?.name);

      this.loadingVisible = true;

      await this.client
        .post(`${environment.apiUrl}${this.url}upload_file`, formData, {
          headers: {
            Authorization: `token ${environment.apiKey}`,
          },
        })
        .toPromise()
        .then((res: any) => {
          this.school_info.cin_recto = res.message.file_url;
        })
        .catch((err) => {
          console.log(err);
          this.showErrorMessage = true;
          this.errorMessage = 'المرجو التأكد من حجم و نوع الملف';
          return;
        });

      formData = new FormData();
      formData.append('file', this.cin_verso!, this.cin_verso?.name);

      await this.client
        .post(`${environment.apiUrl}${this.url}upload_file`, formData, {
          headers: {
            Authorization: `token ${environment.apiKey}`,
          },
        })
        .toPromise()
        .then((res: any) => {
          this.school_info.cin_verso = res.message.file_url;
        })
        .catch((err) => {
          console.log(err);
          this.showErrorMessage = true;
          this.errorMessage = 'المرجو التأكد من حجم و نوع الملف';
          return;
        });

      formData = new FormData();
      formData.append('file', this.photo_perso!, this.photo_perso?.name);

      await this.client
        .post(`${environment.apiUrl}${this.url}upload_file`, formData, {
          headers: {
            Authorization: `token ${environment.apiKey}`,
          },
        })
        .toPromise()
        .then((res: any) => {
          this.school_info.photo_perso = res.message.file_url;
        })
        .catch((err) => {
          console.log(err);
          this.showErrorMessage = true;
          this.errorMessage = 'المرجو التأكد من حجم و نوع الملف';
          return;
        });

      formData = new FormData();
      formData.append('file', this.bilan_cmp!, this.bilan_cmp?.name);

      await this.client
        .post(`${environment.apiUrl}${this.url}upload_file`, formData, {
          headers: {
            Authorization: `token ${environment.apiKey}`,
          },
        })
        .toPromise()
        .then((res: any) => {
          this.school_info.photobilan_cmp_perso = res.message.file_url;
        })
        .catch((err) => {
          console.log(err);
          this.showErrorMessage = true;
          this.errorMessage = 'المرجو التأكد من حجم و نوع الملف';
          return;
        });

      formData = new FormData();
      formData.append('file', this.contrat_ag!, this.contrat_ag?.name);

      await this.client
        .post(`${environment.apiUrl}${this.url}upload_file`, formData, {
          headers: {
            Authorization: `token ${environment.apiKey}`,
          },
        })
        .toPromise()
        .then((res: any) => {
          this.school_info.contrat_ag = res.message.file_url;
        })
        .catch((err) => {
          console.log(err);
          this.showErrorMessage = true;
          this.errorMessage = 'المرجو التأكد من حجم و نوع الملف';
          return;
        });
      this.first_second_state = ClrTimelineStepState.SUCCESS;
      this.first_third_state = ClrTimelineStepState.PROCESSING;
      this.step = 3;
      this.go = false;
      this.loadingVisible = false;
    }

    if (this.step === 3 && this.go) {
      this.openModal = false;
      localStorage.setItem('student_info', JSON.stringify(this.student_info));
      if (!f.valid) {
        this.showErrorMessage = true;
        this.errorMessage = 'المرجو ملء جميع المعلومات الضرورية';
        return;
      }

      if (this.school_info.note && this.school_info.note > 20) {
        this.showErrorMessage = true;
        this.errorMessage = 'االمرجو التأكد من صحة نقطة الباكالوريا';
        return;
      }
      if (this.school_info.note && this.school_info.note < 14) {
        this.showErrorMessage = true;
        this.errorMessage =
          'يجب أن يكون المترشح حاصلا على شهادة الباكالوريا بميزة حسن على الأقل';
        return;
      }

      this.showErrorMessage = false;
      let formData = new FormData();

      formData.append('file', this.file2!, this.file2?.name);

      this.loadingVisible = true;

      await this.client
        .post(`${environment.apiUrl}${this.url}upload_file`, formData, {
          headers: {
            Authorization: `token ${environment.apiKey}`,
          },
        })
        .toPromise()
        .then((res: any) => {
          this.school_info.degree_file_name = res.message.file_url;
        })
        .catch((err) => {
          console.log(err);
          this.showErrorMessage = true;
          this.errorMessage = 'المرجو التأكد من حجم و نوع الملف';
          return;
        });

      formData = new FormData();

      formData.append('file', this.file3!, this.file3?.name);

      await this.client
        .post(`${environment.apiUrl}${this.url}upload_file`, formData, {
          headers: {
            Authorization: `token ${environment.apiKey}`,
          },
        })
        .toPromise()
        .then((res: any) => {
          this.school_info.grades_file_name = res.message.file_url;
        })
        .catch((err) => {
          console.log(err);
          this.showErrorMessage = true;
          this.errorMessage = 'المرجو التأكد من حجم و نوع الملف';
          return;
        });

      await this.client
        .post(
          `${environment.apiUrl}${this.url}create-condidat`,
          { ...this.student_info, code_ag: this.code, ...this.school_info },
          {
            headers: {
              Authorization: `token ${environment.apiKey}`,
            },
          }
        )
        .toPromise()
        .then((res: any) => {
          this.first_third_state = ClrTimelineStepState.SUCCESS;
          this.step = 4;
          console.log(res);
        })
        .catch((err) => {
          this.showErrorMessage = true;
          this.errorMessage = 'لم نتمكن من تسجيل طلبكم، المرجو المحاولة لاحقا';
        });
      this.loadingVisible = false;
    }
  }

  goback() {
    this.step -= 1;
  }

  autoFillGrade() {
    if (this.school_info.note >= 16 && this.school_info.note <= 20) {
      this.school_info.grade = 'حسن جدا';
    } else if (this.school_info.note >= 14 && this.school_info.note < 16) {
      this.school_info.grade = 'حسن';
    } else {
      this.school_info.grade = '';
    }
  }
}
