import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BchlRoutingModule } from './bchl-routing.module';
import { NavbarComponent } from '../navbar/navbar.component';
import { BchApplicationComponent } from '../bch-application/bch-application.component';
import { HomeComponent } from '../home/home.component';
import { PreviousComponent } from '../previous/previous.component';
import { ClarityModule } from '@clr/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { PaqComponent } from '../paq/paq.component';
import { NavigationComponent } from '../navigation/navigation.component';
import { DndDirective } from '../dnd.directive';
import { FooterComponent } from '../footer/footer.component';
import { SocialsComponent } from '../socials/socials.component';

@NgModule({
  declarations: [
    NavbarComponent,
    BchApplicationComponent,
    HomeComponent,
    PaqComponent,
    NavigationComponent,
    DndDirective,
    FooterComponent,
    SocialsComponent,
    PreviousComponent,
  ],
  imports: [
    CommonModule,
    BchlRoutingModule,
    ClarityModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule
  ],
  exports: [
    NavbarComponent,
    NavigationComponent,
    PaqComponent,
    FooterComponent,
  ],
})
export class BchlModule {}
