
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';



declare function startConfettiInner(): any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  videoURL = 'https://www.youtube.com/embed/ayl3s93Q0fU';
  safeURL;
  currentDate: any;
  targetDate: any;
  cDateMillisecs: any;
  tDateMillisecs: any;
  difference: any;
  seconds: any;
  minutes: any;
  hours: any;
  days: any;
  endOfCandidate: boolean = false;
  year: number = 2022;
  month: number = 8;
  months = [
    'Janvier',
    'Fev',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Aout',
    'Septembre',
    'Oct',
    'Nov',
    'Dec',
  ];
  day: number = 30;

  sliderCurrentIndex: number = -1;
  sliders: any = [
    'دكالة',
    'سوراك',
    'سونابيل',
    'سوطا',
    'زايو'
  ];
  sliderTimer: any;

  constructor(private _sanitizer: DomSanitizer) {
    this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(
      this.videoURL
    );
  }

  ngOnInit(): void {
    /*this.myTimer();

    startConfettiInner();
    this.sliderTimer = setInterval(() => {
      this.myTimer();
    }, 8000);*/
  }

  myTimer() {
    /*this.currentDate = new Date();
    this.targetDate = new Date(2022, 8, 14);
    this.cDateMillisecs = this.currentDate.getTime();
    this.tDateMillisecs = this.targetDate.getTime();
    this.difference = this.tDateMillisecs - this.cDateMillisecs;
    this.seconds = Math.floor(this.difference / 1000);
    this.minutes = Math.floor(this.seconds / 60);
    this.hours = Math.floor(this.minutes / 60);
    this.days = Math.floor(this.hours / 24);

    this.hours %= 24;
    this.minutes %= 60;
    this.seconds %= 60;
    this.hours = this.hours < 10 ? '0' + this.hours : this.hours;
    this.minutes = this.minutes < 10 ? '0' + this.minutes : this.minutes;
    this.seconds = this.seconds < 10 ? '0' + this.seconds : this.seconds;
    */



    this.sliderCurrentIndex++;
    if (this.sliders.length === this.sliderCurrentIndex)
      this.sliderCurrentIndex = 0;

  }

  setSliderIndex = (i: number) => {
    this.sliderCurrentIndex = i;
    if (this.sliderTimer) {
      clearInterval(this.sliderTimer);
    }
  }
  pauseSlider = (paused: boolean, idx: number) => {
    if (this.sliderCurrentIndex !== idx)
      return;

    if (paused)
      clearInterval(this.sliderTimer);
    else
      this.sliderTimer = setInterval(() => {
        this.myTimer();
      }, 8000);
  }
}
