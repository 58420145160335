<div class="chtl-socials">
  <div class="chtl-fb">
    <a target="blank" href="https://www.facebook.com/Groupe-Cosumar-340435426289287/">
      <img src="assets/images/fb.png" alt="">
    </a>
  </div>
  <div class="chtl-yt">
    <a target="blank" href="https://www.youtube.com/channel/UCLhpPMZ3Qc9xldJuJZVqsMQ">
      <img src="assets/images/yt.png" alt="">
    </a>
  </div>
  <div class="chtl-li">
    <a target="blank" href="https://ma.linkedin.com/company/groupe-cosumar"> <img src="assets/images/li.png" alt="">
    </a>
  </div>
</div>
