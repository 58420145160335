import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from '../app.component';
import { BchApplicationComponent } from '../bch-application/bch-application.component';
import { PaqComponent } from '../paq/paq.component';
import { HomeComponent } from '../home/home.component';
import { PreviousComponent } from '../previous/previous.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'application', component: HomeComponent },
  { path: 'previous', component: PreviousComponent },
  { path: '#conditions', component: HomeComponent },
  { path: 'faq', component: PaqComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BchlRoutingModule {}
