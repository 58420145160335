<div class="section">
  <div class="main">
    <div class="chtl-header">
      <a href="/#conditions"><img class="app-logo" src="assets/images/applog.png" alt="logo"></a>
      <app-navigation></app-navigation>


    </div>


    <div class="app-text-main" *ngIf="!endOfCandidate">
      <p cds-text class="strong">
        المنح التشجيعية لفائدة أبناء الفلاحين شركاء <strong>كوسومار</strong>
      </p>
      <p cds-text class="light">
        الحاصلين على شهادة الباكالوريا بتفوق برسم سنة <strong> 2023 - 2024
        </strong>
      </p>
    </div>
    <div class="app-video" *ngIf="!endOfCandidate">
      <iframe class="app-video-player" width="500" height="300" [src]="safeURL" title="YouTube video player"
        frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
      <br />
      <div class="wrapper" style="display: none !important;">
        <div class="description">
          <p>Jours</p>
          <p>Heures</p>
          <p>Minutes</p>
          <p>Secondes</p>
        </div>
        <div class="times">
          <p id="days">{{days}}</p>
          <p id="hours">{{hours}}</p>
          <p id="mins">{{minutes}}</p>
          <p id="Seconds">{{seconds}}</p>
        </div>
      </div>

    </div>
  </div>
  <div class="shape"></div>
  <div class="circles" *ngIf="!endOfCandidate">
    <div class="circle ci3 small">
      <div dir="rtl" class="text c3"> منحة إضافية بقيمة<strong> 5.000</strong> درهم للفائزين الحاصلين على ميزة
        <strong>"حسن جدا"</strong>
      </div>
      <div class="chtl-plus">+</div>
    </div>
    <div class="circle ci1">
      <div class="text c1">الأوائل من أبناء منتجي النباتات السكرية من كل جهة و الحاصلين على شهادة الباكالوريا
        بامتياز كتحفيز لمواصلة مسارهم الدراسي</div>
      <div class="number nb1">5</div>
    </div>
    <div class="circle ci2">
      <div class="text c2"> منح <div style="font-weight: bolder;">تشجيعية</div> سنوية
        <p cds-text> بقيمة <strong> 10.000 </strong>درهم</p>

      </div>
      <div class="number nb2">5</div>
    </div>
  </div>
  <div class="conditions" id="conditions" style="direction: rtl;">
    <div class="condition">
      <div class="title">شروط الترشيح:</div>
      <div class="points">
        <div class="point"> أن يكون المرشح من أبناء الفلاحين منتجي النباتات السكرية </div>
        <div class="point"> أن يكون اسم الفلاح المنتج مسجلا في لائحة المنتجين للموسم <strong class="chtl-strong-number">
            2023-2024 </strong>

        </div>
        <div class="point"> أن تكون شهادة الباكالوريا محررة من طرف مؤسسات التعليم العمومي </div>
        <div class="point"> أن لا يتجاوز عمر المرشح <strong class="chtl-strong-number">19</strong> سنة عند حصوله على
          شهادة الباكالوريا
        </div>
        <div class="point"> أن يكون المرشح حاصلا على شهادة الباكالوريا بميزة حسن على الأقل </div>
      </div>
    </div>
    <div class="condition">
      <div class="title">يتضمن ملف الترشيح الوثائق التالية:</div>
      <div class="points">
        <div class="point">نسخة من عقد المنتج
        </div>
        <div class="point"> نسخة من بيان حصيلة الموسم السكري
          <strong class="chtl-strong-number">
            2023-2024 </strong>
        </div>
        <div class="point">نسخة من شهادة الباكالوريا مصادقة عليها و مرفقة ببيان نقط النجاح</div>
        <div class="point">نسخة على الوجهين من البطاقة الوطنية للتلميذ</div>
        <div class="point">صورة إدارية للتلميذ
          <strong class="chtl-strong-number">3,5x4,5cm</strong>
          مع خلفية بيضاء
        </div>
      </div>
    </div>
  </div>
</div>

<div class="footer" style="direction: rtl;">
  <div class="msg">
    <p cds-text>ما عليك سوى اتباع الخطوات الثلاث التالية لتقديم طلبكم </p>
  </div>
  <div class="steps">
    <div class="step">
      <div class="icon">
        <img src="assets/images/user.png" alt="">
      </div>
      <div class="txt"> إدخال رقم المنتج</div>
    </div>
    <div class="arrow">
      <img src="assets/images/yellow-arrow.png" alt="">
    </div>
    <div class="step">
      <div class="icon">
        <img src="assets/images/card.png" alt="">
      </div>
      <div class="txt">إدخال البيانات الشخصية</div>
    </div>
    <div class="arrow">
      <img src="assets/images/yellow-arrow.png" alt="">
    </div>
    <div class="step">
      <div class="icon">
        <img src="assets/images/school.png" alt="">
      </div>
      <div class="txt">إدخال المعطيات الدراسية </div>
    </div>
  </div>

  <div class="chtl-btn" *ngIf="!endOfCandidate">
    <a href="/">
      <button class="chtl-submit-button">أرسل طلبك للمشاركة</button>
    </a>
  </div>
</div>
<app-footer></app-footer>